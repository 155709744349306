<template>
    <div id="AdminMemberReview">
        <ul v-if="reviewMember.length">
            <li
                class="review-item"
                v-for="(item, index) in reviewMember"
                :key="item._id"
            >
                <div class="description-wrap f14 f12-mb">
                    <ElDescriptions
                        class="personal-info"
                        column="1"
                        border="true"
                    >
                        <ElDescriptionsItem label="メールアドレス">{{
                            item.email
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="名前">{{
                            item.name
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="ふりがな">{{
                            item.furigana
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="生年月日">{{
                            item.birth
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="電話番号">{{
                            item.phone
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="住所">{{
                            item.address
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="職業">{{
                            item.job
                        }}</ElDescriptionsItem>
                    </ElDescriptions>
                    <ElDescriptions class="other-info" column="1" border="true">
                        <ElDescriptionsItem label="親育番号">{{
                            item.memberId
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="真資格">{{
                            item.qualification
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="担当真使">{{
                            item.role
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="紹介者">{{
                            item.recommender
                        }}</ElDescriptionsItem>
                        <ElDescriptionsItem label="所属親交所">{{
                            item.location
                        }}</ElDescriptionsItem>
                    </ElDescriptions>
                </div>
                <div class="vertical-button-wrap">
                    <button
                        class="f18 f16-mb"
                        @click.prevent="
                            verifyMember('approve', item._id, index)
                        "
                    >
                        承認
                    </button>
                    <button
                        class="f18 f16-mb"
                        @click.prevent="verifyMember('reject', item._id, index)"
                    >
                        否認
                    </button>
                </div>
            </li>
        </ul>
        <p v-else>個人情報ありません</p>
    </div>
</template>
<script>
export default {
    name: 'AdminMemberReview',
}
</script>
<script setup>
import { onMounted, ref } from 'vue'
import { getReviewMembers, updateVerifyMember } from '@/service/api'

const reviewMember = ref([])
onMounted(async () => {
    let res = await getReviewMembers()
    reviewMember.value = res.data
})

function verifyMember(status, userId, index) {
    reviewMember.value.splice(index, 1)
    updateVerifyMember(status, userId)
}
</script>
<style lang="scss" scoped>
.review-item {
    display: flex;
    margin-bottom: 50px;
}
.description-wrap {
    flex: 1 0 600px;
    display: flex;
    & > div {
        flex: 1;
    }
}
.vertical-button-wrap {
    flex: 0 0 150px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    button {
        @include solidButtonStyle();
        margin-bottom: 10px;
    }
}
</style>
